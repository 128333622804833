import { mapGetters } from "vuex";
import JointUseGridAPI from "../components/dynamic/JointUse/LocalAPI/JointUseGridAPI";
import Axios from "axios";

export default {
  // Register your listener here.
  sockets: {
    ReceiveJUNotification(data) {
      const existingNotification =
        this.$store.state.NotificationStore.notifications;
      if (existingNotification.filter(a => a.id == data.id).length == 0) {
        data.notificationModule = "jointuse";
        // data.clickCallback = () => {
        //   console.log(
        //     "JU click action in notification manager: routeName is: ",
        //     this.$route.name,
        //     ", notificationModule is: ",
        //     data.notificationModule,
        //     ", currentProjectId is: ",
        //     this.$store.getters.currentProjectId,
        //     ", notificationProjectid is: ",
        //     data.projectId
        //   );

        //   if (
        //     this.$route.name === data.notificationModule &&
        //     this.$store.getters.currentProjectId == data.projectId
        //   ) {
        //     this.$store.commit("SET_JU_NOTIFICATIONS_STATUS", true);
        //   } else {
        //     this.$store.commit("SET_JUNOTIFICATION_FROM_OUTSIDE_JU", true);
        //   }
        // };
        existingNotification.push(data);
        if (this.$store.getters.getDownloadManagerPopup) {
          const notifications =
            this.$store.state.NotificationStore.JUReceivedNotification;
          notifications.unshift(data);
          this.$store.dispatch("setJUReceivedNotificationdata", notifications);
        }
      }
      this.$store.dispatch("setNotificationData", existingNotification);
    },
    ReceiveGenericNotification(data) {
      const existingNotification =
        this.$store.state.NotificationStore.notifications;
      // ignore duplicate notifications
      if (existingNotification.filter(a => a.id == data.id).length) return;

      if (data.type === "O360EXPORT") {
        data = this.$store.getters.formatO360ExportNotification(data);
      } else if (data.type === "JU") {
        data = this.$store.getters.formatJUImportNotification(data);
      } else if (data.type == "OsmoViewPDF") {
        data = this.$store.getters.formatOsmoVisionPDFNotification(data);
      } else {
        // data.notificationModule = "generic";
        data.isGeneric = true;
      }

      existingNotification.unshift(data);

      this.$store.dispatch("setNotificationData", existingNotification);
    }
  },
  watch: {
    userAuthObject(newVal, oldVal) {
      if (!oldVal && newVal) {
        if (this.hasJUCapabilities && this.hasJUCapabilities.length > 0) {
          JointUseGridAPI.getDownloadedRequest(
            this.getDownloadRequestUrl,
            this.$store.getters.authToken,
            "notification",
            "jointuse",
            "",
            true
          );
        }

        // the following method now gets mv export notifications as well as JU import notifications
        if (this.hasMVCapabilities?.length || this.hasJUCapabilities?.length) {
          this.$store.dispatch("getMVUnReadExportNotifications");
        }

        this.$socket.connection = `${process.env.VUE_APP_NOTIFICATION_HUB}?access_token=${this.$store.getters.authToken}`;
        // starting signalR;
        this.$socket.start({
          log: false
        });
      }
    }
  },
  computed: {
    ...mapGetters(["authToken"]),
    getDownloadRequestUrl() {
      return process.env.VUE_APP_JOINT_USE_GET_DOWNLOAD_REQUEST;
    },
    userAuthObject() {
      return this.$store.getters.userAuthObject;
    },
    hasJUCapabilities() {
      return this.$store.getters.capabilities("JointUse");
    },
    hasMVCapabilities() {
      return this.$store.getters.capabilities("MapView");
    }
  },
  unmounted() {
    this.$socket.socket.stop();
  }
};
