import { defineStore } from "pinia";
import Store from "@/store/store";
import { O360StoreHelper } from "@/Lib/SharedComponents/mapControl/cosmosAPI/O360StoreHelper";
import { O360ConfigurationAPI } from "../LocalAPI/O360ConfigurationAPI";
import ConfigurationManager from "@/Lib/ConfigurationManager.js";
import payloadManager from "@/Lib/Database/payloadManager";
import { O360MapViewPayloadManager } from "../Lib/Payload/O360MapViewPayloadManager";
import { O360AdvancedSearchClient } from "@/Lib/SharedComponents/mapControl/cosmosAPI/O360AdvancedSearchClient";
import { O360MapControlDataSource } from "@/Lib/SharedComponents/mapControl/cosmosAPI/O360MapControlDataSource";
const o360StoreHelper = new O360StoreHelper();
export const useSideBarStore = defineStore("SideBarStore", {
  state: (): {
    DynamicSearchSections: Record<string, any>;
    LoadingSearch: boolean;
    BulkImageText: string;
    AppliedSearchValues: Record<string, string[]>;
    ClearDynamicFiltersRequests: number;
    // structureDetailsId: number;
    structureDetailsResults: any[];
    resiliencyDetailsResults: any[];
    AdvanceSearchQueryRunning: boolean;
    AttributeQuery: any[];
    exportManagerVisibility: boolean;
    MapQueryRunning: boolean;
    GridQueryRunning: boolean;
    AttributeQueryResubmit: any[];
    NoResultModalVisibility: boolean;
    AdvanceSearchAttributeValues: Record<string, any>;
  } => {
    return {
      DynamicSearchSections: {},
      LoadingSearch: false,
      BulkImageText: "",
      AppliedSearchValues: {},
      // structureDetailsId: 0,
      structureDetailsResults: [],
      resiliencyDetailsResults: [],
      ClearDynamicFiltersRequests: 0,
      AdvanceSearchQueryRunning: false,
      AttributeQuery: [],
      exportManagerVisibility: false,
      MapQueryRunning: false,
      GridQueryRunning: false,
      AttributeQueryResubmit: [],
      NoResultModalVisibility: false,
      AdvanceSearchAttributeValues: {}
    };
  },
  getters: {
    getAttributeQueryResubmit(state): any[] {
      return state.AttributeQueryResubmit;
    },
    getDynamicSearchSectionsRaw(state): any {
      return state.DynamicSearchSections;
    },
    getDynamicSearchSections: state => {
      let modifiedDynamicSearchSections = state.DynamicSearchSections;

      const filterCategories = Store.getters.mapViewFilterAttributes;

      // remove O360InspectionState if it is not enabled as a search category
      if (
        !filterCategories.hasOwnProperty(
          "OUS_LOCATION.OUS_INSPECTION.O360InspectionState"
        )
      ) {
        const {
          "OUS_LOCATION.OUS_INSPECTION.O360InspectionState":
            O360InspectionState,
          ...restOfCategories
        } = modifiedDynamicSearchSections;
        modifiedDynamicSearchSections = restOfCategories;
      }

      // remove StructureType if it is not enabled as a search category
      if (
        !filterCategories.hasOwnProperty(
          "OUS_LOCATION.OUS_STRUCTURE.StructureType"
        )
      ) {
        const {
          "OUS_LOCATION.OUS_STRUCTURE.StructureType": StructureType,
          ...restOfCategories2
        } = modifiedDynamicSearchSections;
        modifiedDynamicSearchSections = restOfCategories2;
      }

      // order sections alphabatically
      const tempOrdered: any = {};
      Object.keys(modifiedDynamicSearchSections)
        .sort((a, b) => {
          const nameA =
            modifiedDynamicSearchSections[a]["FilterDisplayName"] ||
            modifiedDynamicSearchSections[a]["DisplayName"] ||
            modifiedDynamicSearchSections[a]["ColumnName"];
          const nameB =
            modifiedDynamicSearchSections[a]["FilterDisplayName"] ||
            modifiedDynamicSearchSections[b]["DisplayName"] ||
            modifiedDynamicSearchSections[b]["ColumnName"];
          return nameA.localeCompare(nameB);
        })
        .forEach(function (key) {
          tempOrdered[key] = modifiedDynamicSearchSections[key];
        });
      modifiedDynamicSearchSections = tempOrdered;

      // put O360InspectionState, CurrentRejectStatus, O360InspectionState, WeekEndingDate first
      const tempOrdered2: any = {};

      if (
        modifiedDynamicSearchSections.hasOwnProperty(
          "OUS_LOCATION.OUS_INSPECTION.O360InspectionState"
        )
      )
        tempOrdered2["OUS_LOCATION.OUS_INSPECTION.O360InspectionState"] = null;

      if (
        modifiedDynamicSearchSections.hasOwnProperty(
          "OUS_LOCATION.OUS_STRUCTURE.CurrentRejectStatus"
        )
      )
        tempOrdered2["OUS_LOCATION.OUS_STRUCTURE.CurrentRejectStatus"] = null;

      if (
        modifiedDynamicSearchSections.hasOwnProperty(
          "OUS_LOCATION.OUS_STRUCTURE.StructureType"
        )
      )
        tempOrdered2["OUS_LOCATION.OUS_STRUCTURE.StructureType"] = null;

      if (
        modifiedDynamicSearchSections.hasOwnProperty(
          "OUS_LOCATION.OUS_INSPECTION.WeekEndingDate"
        )
      )
        tempOrdered2["OUS_LOCATION.OUS_INSPECTION.WeekEndingDate"] = null;

      modifiedDynamicSearchSections = {
        ...tempOrdered2,
        ...modifiedDynamicSearchSections
      };
      return modifiedDynamicSearchSections;
    },
    currentProjectRejectStatuses: state => {
      if (
        state.DynamicSearchSections?.[
          "OUS_LOCATION.OUS_STRUCTURE.CurrentRejectStatus"
        ].options
      ) {
        return Array.from(
          state.DynamicSearchSections[
            "OUS_LOCATION.OUS_STRUCTURE.CurrentRejectStatus"
          ].options
        ).map((a: any) => a.value);
      } else {
        return [];
      }
    },
    currentProjectVisitStatuses: state => {
      if (
        state.DynamicSearchSections?.[
          "OUS_LOCATION.OUS_INSPECTION.O360InspectionState"
        ]?.options
      ) {
        return Array.from(
          state.DynamicSearchSections[
            "OUS_LOCATION.OUS_INSPECTION.O360InspectionState"
          ].options
        ).map((a: any) => a.value);
      } else {
        return [];
      }
    },
    currentProjectAttributeValues: state => (attrName: string) => {
      if (
        state.DynamicSearchSections &&
        state.DynamicSearchSections[attrName] &&
        state.DynamicSearchSections[attrName]?.options
      ) {
        return Array.from(state.DynamicSearchSections[attrName].options).map(
          (a: any) => a.value
        );
      } else {
        return [];
      }
    },
    loadingSearch(state): boolean {
      return state.LoadingSearch;
    },
    bulkImageText(state): string {
      return state.BulkImageText;
    },
    advanceSearchQueryRunning(state): boolean {
      return state.AdvanceSearchQueryRunning;
    },
    appliedSearchValues(state): Record<string, string[]> {
      return state.AppliedSearchValues;
    },
    appliedSearchesExist(state): boolean {
      const data =
        Object.keys(state.AppliedSearchValues).length > 0 ||
        // Object.keys(SideBarStore.getAttributeQueryResubmit).length > 0 ||
        // // Object.keys(state.AttributeQuery).length > 0;
        // (Object.keys(SideBarStore.getAttributeQuery).length > 0 &&
        //   searchResultsStore.getSearchResults.length > 0) ||
        o360StoreHelper.getHighLightLayerCount() > 0;
      return data;
    },
    clearDynamicFiltersRequests(state): number {
      return state.ClearDynamicFiltersRequests;
    },
    // getStructureDetailsId(state) {
    //   return state.structureDetailsId;
    // },
    getResiliencyDetailsResults(state) {
      return state.resiliencyDetailsResults;
    },
    getStructureDetailsResults(state) {
      return state.structureDetailsResults;
    },
    getAttributeQuery(state) {
      console.log("getting attribute query");
      console.log(state.AttributeQuery);
      return state.AttributeQuery;
    },
    schemaForAdvancedSearch(state) {
      const advancedSearchVisibility = [];
      const visibility = Store.getters.visibility.Schema;

      // get distinct list of table names for profit centers.
      const tables: any = [
        ...new Set(
          Object.values(
            Store.getters.currentCustProjectConfig?.ProfitCenterVisibility
          ).flatMap(t => t)
        )
      ].map((t: any) => {
        t = t.toUpperCase();
        if (t !== "OUS_LOCATION") {
          if (t === "OUS_INSPECTION" || t === "OUS_STRUCTURE") {
            t = "OUS_LOCATION." + t;
          } else {
            t = "OUS_LOCATION.OUS_INSPECTION." + t;
          }
        }
        return t;
      });

      for (const table of tables) {
        for (const col of Object.keys(visibility[table] || {})) {
          const column = visibility[table][col];

          const inputType = convertToInputType(
            column.DataType,
            column.SQLDataType
          );

          if (column.MapViewEnabled) {
            advancedSearchVisibility.push({
              ColumnName: column.ColumnName,
              TableName: column.TableName,
              SQLDataType: column.SQLDataType,
              DataType: column.DataType,
              inputType,
              type: column.SQLDataType,
              id: `${column.TableName}.${column.ColumnName}`,
              code: `${column.TableName}.${column.ColumnName}`,
              label: getDisplayName(
                column.TableName,
                column.ColumnName,
                column.DisplayName
              ),
              operators:
                column.DataType !== "boolean"
                  ? getConditionsByType(inputType)
                  : [],
              ...(column.DataType === "boolean" && {
                choices: [
                  { label: "True", value: "true" },
                  { label: "False", value: "false" }
                ]
              })
            });
          }
        }
      }
      return advancedSearchVisibility
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              t =>
                t.ColumnName === value.ColumnName &&
                t.TableName === value.TableName
            )
        )
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
    },
    getExportManagerVisibility(state) {
      return state.exportManagerVisibility;
    },
    getMVQueriesRunning(state) {
      return state.MapQueryRunning || state.GridQueryRunning;
    },
    getNoResultModalVisibility(state) {
      return state.NoResultModalVisibility;
    },
    advanceSearchAttributeValues(state) {
      return state.AdvanceSearchAttributeValues;
    }
  },
  actions: {
    setDynamicSearchSections(obj: any) {
      this.DynamicSearchSections = obj;
    },
    setDynamicSearchSectionOptions(params: any) {
      const formatedOptions = new Set();

      let key = `${params.table.replaceAll(".", "_")}_${params.attribute}`;
      params.options.forEach((p: any) => {
        const val = p[key];
        if (val) formatedOptions.add(val);
      });

      key = `${params.table}.${params.attribute}`;
      this.DynamicSearchSections[key].options = processItems(
        params.attribute,
        formatedOptions
      );
      // this.DynamicSearchSections = { ...this.DynamicSearchSections };
      console.log(
        params.attribute +
          " filter section options have been recieved and set with " +
          params.options.length +
          " values."
      );
    },
    updateLoadingSearch(value: boolean) {
      this.LoadingSearch = value;
    },
    updateBulkImageText(value: string) {
      this.BulkImageText = value;
    },
    updateAppliedSearchValues(value: Record<string, string[]>) {
      this.AppliedSearchValues = value;
    },
    updateAttributeQueryWithPayLoad(value: any[]) {
      console.log("setting updateattributequerywithpayload");
      console.log(value);
      this.AttributeQuery = value;
    },
    // TODO: call this whenever we initializeStores
    updateClearDynamicFiltersRequests() {
      this.ClearDynamicFiltersRequests++;
    },
    // setStructureDetailsId(structureDetailsId: number) {
    //   this.structureDetailsId = structureDetailsId;
    // },
    setStructureDetailsResults(structureDetailsResults: any[]) {
      this.structureDetailsResults = structureDetailsResults;
    },
    setResiliencyDetailsResults(resiliencyDetailsResults: any[]) {
      this.resiliencyDetailsResults = resiliencyDetailsResults;
    },
    setAdvanceSearchQueryRunning(value: boolean) {
      this.AdvanceSearchQueryRunning = value;
    },
    deleteUserSavedSearch: async (deleteSearchName: string) => {
      const projectConfig = o360StoreHelper.getUserProjectConfigProxy();
      const advancedSearches = projectConfig[0]?.MVAdvancedSearch
        ? JSON.parse(JSON.stringify(projectConfig[0]?.MVAdvancedSearch))
        : [];

      const newAdvancedSearches = advancedSearches.filter(
        (s: any) => s.Name.toLowerCase() != deleteSearchName.toLowerCase()
      );
      const O360Configs = new O360ConfigurationAPI();
      if (newAdvancedSearches.length < advancedSearches.length) {
        try {
          await O360Configs.upsertUserProjectConfig(
            "MVAdvancedSearch",
            newAdvancedSearches
          );
          return "save";
        } catch {
          return "error";
        }
      }
    },
    saveAdvanceSearchToProjectConfig(newQuery: any) {
      const updatedApplicationDO = JSON.parse(
        JSON.stringify(o360StoreHelper.getProjectApplicationConfigProxy())
      );

      // add user guid to the saved search
      newQuery.UserId = Store.getters.userId;

      const newMVAdvanceSearch =
        Store.getters.projectSavedAdvancedSearches ?? [];

      if (
        newMVAdvanceSearch.find(
          (s: any) => s.Name.toLowerCase() === newQuery.Name.toLowerCase()
        ) !== undefined
      ) {
        newMVAdvanceSearch.forEach((s: any) => {
          if (s.Name.toLowerCase() === newQuery.Name.toLowerCase()) {
            s.Query = newQuery.Query;
          }
        });
      } else {
        newMVAdvanceSearch.push(newQuery);
      }

      updatedApplicationDO.MVAdvancedSearch = newMVAdvanceSearch;

      return ConfigurationManager.updateOrAddO360ApplicationObj(
        updatedApplicationDO
      )
        .then(_ => {
          Store.dispatch("updateSavedAdvancedSearches", newMVAdvanceSearch);
        })
        .then(_ => "save");
    },
    saveUserProjectConfig: async (newQuery: any) => {
      const projectConfig = o360StoreHelper.getUserProjectConfigProxy();

      let newQueryAdded = false;
      const advancedSearches = projectConfig[0]?.MVAdvancedSearch
        ? JSON.parse(JSON.stringify(projectConfig[0]?.MVAdvancedSearch))
        : [];

      if (
        advancedSearches.find(
          (s: any) => s.Name.toLowerCase() === newQuery.Name.toLowerCase()
        ) !== undefined
      ) {
        advancedSearches.forEach((s: any) => {
          if (s.Name.toLowerCase() === newQuery.Name.toLowerCase()) {
            s.Query = newQuery.Query;
          }
        });
      } else {
        advancedSearches.push(newQuery);
        newQueryAdded = true;
      }
      // Update
      const O360Configs = new O360ConfigurationAPI();
      try {
        await O360Configs.upsertUserProjectConfig(
          "MVAdvancedSearch",
          advancedSearches
        );
        return "save";
      } catch {
        return "error";
      }
    },
    setExportManagerVisibility(exportManagerVisibility: boolean) {
      this.exportManagerVisibility = exportManagerVisibility;
    },
    updateMVQueriesRunning(value: boolean) {
      this.MapQueryRunning = value;
      this.GridQueryRunning = value;
    },
    updateGridQueryRunning(value: boolean) {
      this.GridQueryRunning = value;
    },
    updateMapQueryRunning(value: boolean) {
      this.MapQueryRunning = value;
    },
    setAttributeQueryResubmit(attributeQueryResubmit: any[]) {
      this.AttributeQueryResubmit = attributeQueryResubmit;
    },
    setNoResultModalVisibility(noResultModalVisibility: boolean) {
      this.NoResultModalVisibility = noResultModalVisibility;
    },
    // fetches unique values for an attribute in the visibility for use in Advanced Search
    async fetchVisibilityAttributeValue(payload: any) {
      const {
        TableName,
        ColumnName,
        ReferencedColumn,
        ReferencedTable,
        SQLDataType,
        DataType
      } = payload.attribute;
      const querySpec: {
        Rules: any[];
        Format: string;
        CustomerId: string;
        Visibility: any;
        GroupByFields: string[];
        queryName: string;
        errorMessage: string;
        ColumnList: { Field: string }[];
      } = {
        ...payloadManager.getParentRule("OR"),
        Format: "JSON",
        CustomerId: "",
        Visibility: {},
        GroupByFields: [],
        queryName: "",
        errorMessage: "",
        ColumnList: []
      };

      const visitStatuses = [
        "Out for Collection",
        "Prefield",
        "Preliminary",
        "Delivered"
      ];

      const visitStatusRules = payloadManager.getRuleForSingleAndMultipleValues(
        {
          field: "OUS_LOCATION.OUS_INSPECTION.VisitStatus",
          type: "string",
          operator: "equal",
          values: visitStatuses
        }
      );

      querySpec.Rules.push(visitStatusRules);
      const options = { queryFormat: "TABLE_VIEW" };
      const o360StoreHelper = new O360StoreHelper();
      const configs = o360StoreHelper.getConfigurationsProxy();
      const filterRules = O360MapViewPayloadManager.getFilterConfiguration(
        configs,
        options
      );

      querySpec.Rules.push(filterRules);

      querySpec.CustomerId = Store.getters.currentCustomerId;
      querySpec.Visibility = {
        Schema: {
          [formatedTableName(TableName)]: {
            [ColumnName]: {
              TableName: TableName,
              ColumnName: ColumnName,
              SQLDataType: SQLDataType, //"string",
              ReferencedColumn: ReferencedColumn, //null, //
              ReferencedTable: ReferencedTable, //null, //
              DataType: DataType
            }
          }
        }
      };
      querySpec.GroupByFields = [`${TableName}.${ColumnName}`];

      querySpec.queryName = "get" + ColumnName;
      querySpec.errorMessage = `Could not retrieve filter configurations. Please try again. 
        If this error continues to occur, please contact support.`;
      querySpec.ColumnList = [
        {
          Field: `${TableName}.${ColumnName}`
        }
      ];

      const o360CosmosDataSource = new O360MapControlDataSource();
      const o360AdvancedSearchClient = new O360AdvancedSearchClient();
      o360AdvancedSearchClient.dispatch = this.updateVisibilityValue;
      o360AdvancedSearchClient.params = {
        Name: `${TableName}.${ColumnName}`
      };
      o360CosmosDataSource.registerClient(o360AdvancedSearchClient);
      o360CosmosDataSource.payload = querySpec;
      o360CosmosDataSource.projectId = Store.getters.currentProjectId;
      // this needs to do what group by data does, override the execute method
      await o360CosmosDataSource.initData();
    },
    updateVisibilityValue(callback: string, params: any) {
      const MAX_NUMBER = 500;
      this.AdvanceSearchAttributeValues[params.additonalParams.Name] = {
        values: params.results
          .flatMap((r: any) => Object.values(r).filter(r => r))
          .sort((a: any, b: any) => (a < b ? -1 : a > b ? 1 : 0))
          .slice(0, MAX_NUMBER),
        maxExceeded: params.results.length > MAX_NUMBER ? true : false
      };
    },
    clearAttributesValues() {
      this.AdvanceSearchAttributeValues = {};
    }
  }
});

// Helper functions
function formatedTableName(tableName: string) {
  if (tableName.includes(".")) {
    const tempArr = tableName.split(".");
    return tempArr[tempArr.length - 1];
  } else {
    return tableName;
  }
}

function convertToInputType(dataType: string, sqlDataType: string) {
  const type = sqlDataType?.includes("date") ? "date" : dataType;

  switch (type.toUpperCase()) {
    case "STRING":
      return "text";
    case "DOUBLE":
    case "INTEGER":
      return "number";
    case "DATE":
      return "date";
    case "BOOLEAN":
      return "radio";
    default:
      return "text";
  }
}

function getDisplayName(
  tableName: string,
  columnName: string,
  displayName: string
) {
  return `${
    tableName.substring(tableName.lastIndexOf(".") + 1).split("_")[1]
  }.${displayName || columnName}`;
}

function getConditionsByType(type: string) {
  type = type || "";

  switch (type.toUpperCase()) {
    case "TEXT":
      return STRING_OPERATORS;
    case "DATE":
      return DATE_OPERATORS;
    case "NUMBER":
      return INT_OPERATORS;
    default:
      return [];
  }
}

const STRING_OPERATORS = [
  "Equals",
  "Does not equal",
  "Contains",
  "Does not contain",
  "Begins with",
  "Is empty",
  "Is not empty"
];
const DATE_OPERATORS = [
  "Equals",
  "After",
  "After or on",
  "Before",
  "Before or on"
];
const INT_OPERATORS = [
  "Equals",
  "Greater than",
  "Greater than or equals",
  "Less than",
  "Less than or equals"
];

function processItems(category: string, set: any) {
  let sortedArr;
  switch (category) {
    case "State":
      // sort
      sortedArr = Array.from(set).sort();
      // format
      return new Set(
        sortedArr.map(
          item =>
            (item = {
              value: item,
              text: Store.getters.stateName(item)
            })
        )
      );
    case "County":
      sortedArr = Array.from(set).sort((a: any, b: any): number => {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        } else if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
      // format
      return new Set(
        sortedArr.map(
          item =>
            (item = {
              value: item,
              text: item
            })
        )
      );
    case "WeekEndingDate":
      // sort
      sortedArr = [...set];
      function dateToNum(d: any) {
        d = d.split(new RegExp("[-_/:+ ]", "g"));
        return Number(d[2] + d[0] + ("0" + d[1]).slice(-2));
      }
      sortedArr.sort(function (a, b) {
        return dateToNum(b) - dateToNum(a);
      });
      // format
      return new Set(
        sortedArr.map(
          item => (item = { text: item.replace(/_/g, "/"), value: item })
        )
      );
    case "CustStatus":
      // sort
      const order = ["Open", "In Progress", "Closed", "No Action"];
      let orderedSet: any[] = [];
      const originalSet = Array.from(set);
      order.forEach(function (item) {
        const indexOfStatus = originalSet.findIndex(element => item == element);
        if (indexOfStatus > -1) {
          // push status to orderedSet and remove it from originalSet
          const status = originalSet.splice(indexOfStatus, 1);
          orderedSet.push({
            value: status[0],
            text: status[0]
          });
        }
      });

      // if there are any element left in originalSet then concat them at the end
      orderedSet = orderedSet.concat(
        originalSet.map(item => {
          return { value: item, text: item };
        })
      );
      return new Set(orderedSet);

    default:
      sortedArr = [...set].sort();
      return new Set(
        sortedArr.map(
          item =>
            (item = {
              value: item,
              text: item
            })
        )
      );
  }
}
