import axios from "axios";
import Store from "@/store/store";
import { useSearchResultsStore } from "../LocalStore/SearchResultsStore";
export class O360ConfigurationAPI {
  async getUniqueValueRenderers(
    defaultLayers: any[],
    customerId: any,
    authToken: string
  ): Promise<any> {
    const uniqueValueRenderers: any = {};

    const URLTemplate = process.env.VUE_APP_UNIQUE_VALUE_RENDERER_URL
      ? `${process.env.VUE_APP_UNIQUE_VALUE_RENDERER_URL}?code=${process.env.VUE_APP_PROJECT_SCHEMA_SERVICE_KEY}`
      : "";
    for (let x = 0; x < defaultLayers.length; x++) {
      const layer: any = defaultLayers[x];
      const URL: string = URLTemplate?.replace(
        "{customerId}",
        customerId
      ).replace("{id}", layer.Id);
      uniqueValueRenderers[layer.Id] = await axios
        .get(URL, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          console.log("Error: Could not get the unique value renderer");
          console.dir(error);
          const errorObj = {
            requestName: "getUserProjectConfiguration",
            errorMessage: `Could not retrieve the unique value renderer. Please try again.
    If this error continues to occur, please contact support.`,
            Error: error
          };
          Store.dispatch("updateError", errorObj);
        });
    }

    return uniqueValueRenderers;
  }
  async upsertUserProjectConfig(property: string, payloadParam: any) {
    // Right now this is dependent on the userProjectConfig in SideBarStore

    const SearchResultsStore = useSearchResultsStore();
    if (
      SearchResultsStore.getUserProjectConfiguration &&
      SearchResultsStore.getUserProjectConfiguration !==
        "No User Project Config Found"
    ) {
      await this.updateUserProjectConfig(
        `${Store.getters.userId}_${Store.getters.currentProjectId}`,
        payloadParam,
        property
      );
      const data = await this.getUserProjectConfig(
        Store.getters.userId,
        Store.getters.currentProjectId,
        Store.getters.authToken
      );
      SearchResultsStore.setUserProjectConfigs(data);
    } else {
      const { currentProjectId, currentCustomerId, userId, authToken } =
        Store.getters;
      const payload = {
        ProjectId: currentProjectId,
        UserId: userId,
        CustomerId: currentCustomerId,
        [property]: payloadParam
      };
      await this.saveUserProjectConfig(payload);
      const data = await this.getUserProjectConfig(
        userId,
        currentProjectId,
        authToken
      );
      SearchResultsStore.setUserProjectConfigs(data);
    }
  }

  async updateUserProjectConfig(Id: string, UpdatedObject: any, Type: string) {
    const payload: any = {
      Type
    };
    //  debugger;
    payload[Type] = UpdatedObject;
    const URL = `${process.env.VUE_APP_UPDATE_USER_PROJECT_CONFIG}${Id}?code=${process.env.VUE_APP_USER_PROJECT_CONFIG_KEY}`;
    return axios.post(URL, payload);
  }
  async saveUserProjectConfig(payload: any) {
    // debugger;
    const URL = `${process.env.VUE_APP_SAVE_USER_PROJECT_CONFIG}?code=${process.env.VUE_APP_USER_PROJECT_CONFIG_KEY}`;
    return axios.post(URL, payload);
  }
  async getUserProjectConfig(
    userId: string,
    projectId: number,
    authToken: string
  ) {
    const URL = `${process.env.VUE_APP_USER_FILTER_CONFIGURATION}${userId}/${projectId}/All?code=${process.env.VUE_APP_USER_PROJECT_CONFIG_KEY}`;
    //  debugger;
    const userProjectConfig = await axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error: Could not get the user project configuration");
        console.dir(error);
        const errorObj = {
          requestName: "getUserProjectConfiguration",
          errorMessage: `Could not retrieve the user project information. Please try again. 
          If this error continues to occur, please contact support.`,
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
    return userProjectConfig;
  }
  async getO360ApplicationObj(
    application: string,
    projectId: number,
    authToken: string,
    URL: string
  ) {
    console.log("in get project for user");
    const url = `${URL}${projectId}/${application}`;
    const projectInfo = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${authToken}` }
      })

      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error: Could not get the project's application configuration"
        );
        console.dir(error);
        const errorObj = {
          requestName: "getProjectApplicationConfiguration",
          errorMessage: `Could not retrieve the project information. Please try again. 
            If this error continues to occur, please contact support.`,
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
    return projectInfo;
  }
  async getProjectMVSearchConfig(projectId: number) {
    console.log("making request to get search configuration");
    const url = `${process.env.VUE_APP_PROJECT_MV_SEARCH_CONFIGURATION}${projectId}?code=${process.env.VUE_APP_PROJECT_SCHEMA_SERVICE_KEY}`;
    // clear current object first
    Store.dispatch("updateProjectMVSearchConfiguration", null);
    const projectMVSearchConfig = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${Store.getters.authToken}` }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error: Could not get the project's MV search configuration"
        );
        console.dir(error);
        const errorObj = {
          requestName: "getProjectMVSearchConfig",
          errorMessage: `Could not retrieve project's search configuration. Please try again. 
            If this error continues to occur, please contact support.`,
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
    return projectMVSearchConfig;
  }
}
